import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import Header from '../components/header'
import { renderDate } from '../utils/renders'

import styles from './event-page.module.css'

class EventPageTemplate extends React.Component {
  render() {
    const pageData = get(this.props, 'data.contentfulEvent')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div className={'pageContent'}>
          <Helmet title={`${pageData.title} - ${siteTitle || 'Zonta Club of Toronto'}`} />
          <Header
            backgroundVideo={pageData.heroVideo}
            backgroundImage={pageData.heroImage}
            title={pageData.title}
            description={renderDate(pageData.dateTime)}
          />
          <div className="wrapper">
            <div className="body-markup">
              {/* <p>{pageData.description}</p> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.description.childMarkdownRemark.html,
                }}
              />
              {pageData.cost >= 0 && (
                <p>
                  <strong>Entry Fee: </strong>
                  <span>{pageData.cost && pageData.cost <= 0 ? 'Free' : `$${pageData.cost}`}</span>
                </p>
              )}
              {pageData.locationName && (
                <p>
                  <strong>Location: </strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.locationName.childMarkdownRemark.html,
                    }}
                  />
                </p>
              )}
              {pageData.url && <a href={pageData.url} className={`${styles.eventCta} cta button primary`} target={'_blank'} rel="noreferrer"><span className={'innerText'}>Register</span></a>}
            </div>
            <Link to={`/events`} className={`cta textLink primary iconLink iconLeft backArrow ${styles.backCta}`}>All Events</Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default EventPageTemplate

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      heroImage {
        description
        fluid {
          src
          srcSetWebp
          srcSet
        }
        file {
          contentType
        }
      }
      heroVideo {
        file {
          url
          contentType
        }
        description
        title
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      title
      slug
      locationName {
        childMarkdownRemark {
          html
        }
      }
      location {
        lat
        lon
      }
      dateTime
      cost
      url
    }
  }
`
